import { render, staticRenderFns } from "./OrganismAddCarAddLicensePlate.vue?vue&type=template&id=12977b2d&scoped=true"
import script from "./OrganismAddCarAddLicensePlate.vue?vue&type=script&lang=ts"
export * from "./OrganismAddCarAddLicensePlate.vue?vue&type=script&lang=ts"
import style0 from "./OrganismAddCarAddLicensePlate.vue?vue&type=style&index=0&id=12977b2d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12977b2d",
  null
  
)

export default component.exports