
import Vue from 'vue';
import AtomSegmentedControl from '@/components/atoms/AtomSegmentedControl.vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'OrganismAddCarSetDefault',
  components: { AtomSegmentedControl },
  computed: {
    ...mapGetters('createCar', ['getIsDefaultCar']),
  },
  methods: {
    ...mapActions('createCar', ['setIsDefaultCar']),
  },
});
