
import Vue from 'vue';
import AtomAddImage from '@/components/atoms/AtomAddImage.vue';
import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'OrganismAddCarAddImage',
  components: {
    AtomAddImage,
    AtomCheckbox,
  },
  data: () => ({
    showSkipImage: true,
  }),
  computed: {
    ...mapGetters('createCar', ['getImage', 'getSkipImage']),
    getClasses(): any {
      return {
        'add-car--image-wrapper': true,
        negativeMargin: !this.showSkipImage,
      };
    },
  },
  methods: {
    ...mapActions('createCar', ['setImage', 'setSkipImage']),
    onCropperReady() {
      this.showSkipImage = false;
      this.validateInputs(false);
      this.$emit('ready');
    },
    validateInputs(status: boolean) {
      this.$emit('validate', status);
    },
    onCheckboxChange(checked: boolean): void {
      this.setSkipImage(checked);
      this.validateInputs(checked);
    },
    setClippedImage(image: string): void {
      this.setImage(image);
      this.setSkipImage(false);
      this.validateInputs(true);
      this.showSkipImage = true;
      this.$emit('finished');
    },
  },
  mounted() {
    this.validateInputs(this.getImage || this.getSkipImage);
  },
});
