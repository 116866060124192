
import Vue from 'vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'OrganismAddCarAddLicensePlate',
  components: {
    AtomTextInput,
  },
  data() {
    return {
      isInputValid: false,
    };
  },
  computed: {
    ...mapGetters('createCar', ['getLicensePlate']),
  },
  methods: {
    ...mapActions('createCar', ['setLicensePlate']),
    setIsInputValid(valid: boolean) {
      this.isInputValid = valid;
      this.validateInputs();
    },
    validateInputs() {
      if (this.isInputValid) {
        this.$emit('validate', true);
      } else {
        this.$emit('validate', false);
      }
    },
  },
  mounted() {
    this.validateInputs();
  },
});
