
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomSegmentedControl',
  props: {
    values: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIndex: this.selected,
    };
  },
  methods: {
    selectIndex(index: number) {
      if (!this.disabled) {
        this.selectedIndex = index;
        this.$emit('change', index);
      }
    },
  },
  computed: {
    getStyles() {
      return {
        width: `calc(100% / ${this.values.length})`,
        // Ignore TypeScript Error due to Vue TS Bug
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        left: `calc((100% / ${this.values.length}) * ${this.selectedIndex})`,
      };
    },
    getWrapperClasses() {
      return {
        'segmented-control': true,
        disabled: this.disabled,
      };
    },
    getClasses() {
      return {
        'segmented-control--selection': true,
        // Ignore TypeScript Error due to Vue TS Bug
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        'segmented-control--selection--border-left': this.selectedIndex === 0,
        // Ignore TypeScript Error due to Vue TS Bug
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        'segmented-control--selection--border-right': this.selectedIndex + 1 === this.values.length,
      };
    },
  },
});
