
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomProgressBar',
  props: {
    max: {
      type: Number,
      default: 10,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    getClipPath(): string {
      const percentValue = (this.value / this.max) * 100;
      return `polygon(0 0, ${percentValue}% 0, ${percentValue}% 100%, 0% 100%);`;
    },
  },
});
