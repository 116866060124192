
import Vue, { PropType } from 'vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export enum StepperSize {
  DEFAULT = 'DEFAULT',
  BIG = 'BIG',
}

export default Vue.extend({
  name: 'AtomInputStepper',
  components: {
    AtomSvgIcon,
  },
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    initialValue: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    textPluralization: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String as PropType<StepperSize>,
      validator: (val: StepperSize) => Object.values(StepperSize).includes(val),
      default: StepperSize.DEFAULT,
    },
  },
  data: () => ({
    currentValue: 0,
  }),
  computed: {
    getIconSize(): string {
      if (this.size === StepperSize.BIG) return '35';
      return '24';
    },
  },
  methods: {
    increase() {
      const value = this.currentValue === this.max ? this.max : this.currentValue + 1;
      this.emitChange(value);
    },
    decrease() {
      const value = this.currentValue === this.min ? this.min : this.currentValue - 1;
      this.emitChange(value);
    },
    emitChange(newValue: number) {
      if (!this.disabled) {
        this.currentValue = newValue;
        this.$emit('change', this.currentValue);
      }
    },
  },
  mounted() {
    if (this.initialValue >= this.max) this.currentValue = this.max;
    else if (this.initialValue <= this.min) this.currentValue = this.min;
    else this.currentValue = this.initialValue;
  },
});
