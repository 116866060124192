
import Vue from 'vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'OrganismAddCarAddModel',
  components: {
    AtomTextInput,
  },
  computed: {
    ...mapGetters('createCar', ['getDescription']),
  },
  methods: {
    ...mapActions('createCar', ['setDescription']),
  },
});
