
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { LocaleMessage } from 'vue-i18n';
import { createCar, deleteCarImage, updateCar } from '@/api/car/carApi';
import { CreateCar } from '@/store/modules/create-car/state';
import { CarResponse } from '@/models/car/response/CarResponseModel';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomProgressBar from '@/components/atoms/AtomProgressBar.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import OrganismAddCarAddImage from './OrganismAddCarAddImage.vue';
import OrganismAddCarAddLicensePlate from './OrganismAddCarAddLicensePlate.vue';
import OrganismAddCarAddModel from './OrganismAddCarAddModel.vue';
import OrganismAddCarAddFuel from './OrganismAddCarAddFuel.vue';
import OrganismAddCarSetSeats from './OrganismAddCarSetSeats.vue';
import OrganismAddCarSetDefault from './OrganismAddCarSetDefault.vue';

enum AddCarState {
  IMAGE = 'IMAGE',
  LICENSE_PLATE = 'LICENSE_PLATE',
  MODEL = 'MODEL',
  // COLOR = 'COLOR',
  FUEL = 'FUEL',
  SEATS = 'SEATS',
  DEFAULT = 'DEFAULT',
}

export default Vue.extend({
  name: 'OrganismAddCar',
  components: {
    MoleculeModal,
    AtomButton,
    AtomProgressBar,
    OrganismAddCarAddImage,
    OrganismAddCarAddLicensePlate,
    OrganismAddCarAddModel,
    OrganismAddCarAddFuel,
    OrganismAddCarSetSeats,
    OrganismAddCarSetDefault,
  },
  data: () => ({
    AddCarState,
    ButtonVariant,
    currentState: {
      id: 1,
      state: AddCarState.IMAGE,
    },
    isStepValid: false,
    isCropperReady: false,
    isLoading: false,
  }),
  computed: {
    ...mapGetters('createCar', ['getAll', 'getSkipImage']),
    ...mapGetters('car', ['getCarById']),
    ...mapGetters('user', ['getUserId']),
    car(): CreateCar {
      return this.getAll;
    },
    hasImage(): boolean {
      return this.car.decodedImageURL !== '';
    },
    getMaxStates(): number {
      return Object.keys(AddCarState).length;
    },
    getHeading(): LocaleMessage {
      switch (this.currentState.state) {
        case AddCarState.IMAGE:
          return this.$t('myCars.addCarModal.addImageHeading');
        case AddCarState.LICENSE_PLATE:
          return this.$t('myCars.addCarModal.addLicensePlateHeading');
        case AddCarState.MODEL:
          return this.$t('myCars.addCarModal.addModelHeading');
        case AddCarState.FUEL:
          return this.$t('myCars.addCarModal.addFuelHeading');
        case AddCarState.SEATS:
          return this.$t('myCars.addCarModal.addSeatCountHeading');
        case AddCarState.DEFAULT:
          return this.$t('myCars.addCarModal.isDefaultCarHeading');
        default:
          return '';
      }
    },
  },
  methods: {
    ...mapActions('createCar', ['resetCarState', 'loadCar', 'setImage']),
    ...mapActions('car', ['addCar', 'updateCar']),
    setStepValid(value) {
      this.isStepValid = value;
    },
    nextButtonClick() {
      switch (this.currentState.state) {
        case AddCarState.IMAGE:
          this.currentState = { id: 2, state: AddCarState.LICENSE_PLATE };
          this.setStepValid(false);
          break;
        case AddCarState.LICENSE_PLATE:
          this.currentState = { id: 3, state: AddCarState.MODEL };
          this.setStepValid(false);
          break;
        case AddCarState.MODEL:
          this.currentState = { id: 4, state: AddCarState.FUEL };
          this.setStepValid(false);
          break;
        case AddCarState.FUEL:
          this.currentState = { id: 5, state: AddCarState.SEATS };
          break;
        case AddCarState.SEATS:
          this.currentState = { id: 6, state: AddCarState.DEFAULT };
          break;
        case AddCarState.DEFAULT:
          this.sendCreateOrUpdateCarRequest();
          break;
        default:
          break;
      }
    },
    backButtonClick() {
      switch (this.currentState.state) {
        case AddCarState.LICENSE_PLATE:
          this.currentState = { id: 1, state: AddCarState.IMAGE };
          break;
        case AddCarState.MODEL:
          this.currentState = { id: 2, state: AddCarState.LICENSE_PLATE };
          break;
        case AddCarState.FUEL:
          this.currentState = { id: 3, state: AddCarState.MODEL };
          break;
        case AddCarState.SEATS:
          this.currentState = { id: 4, state: AddCarState.FUEL };
          break;
        case AddCarState.DEFAULT:
          this.currentState = { id: 5, state: AddCarState.SEATS };
          break;
        default:
          break;
      }
    },
    initState() {
      this.isStepValid = this.hasImage || this.getSkipImage;
    },
    resetState() {
      this.resetCarState();
      this.currentState = {
        id: 1,
        state: AddCarState.IMAGE,
      };
      this.setStepValid(false);
      this.isCropperReady = false;
    },
    async sendCreateOrUpdateCarRequest() {
      this.isLoading = true;
      const carObject = this.getAll;
      delete carObject.skipImage;
      let response = {} as CarResponse;
      if (carObject.id) {
        response = await updateCar(this.getUserId, carObject);
      } else {
        response = await createCar(this.getUserId, carObject);
      }
      if (response.status === 200 || response.status === 201) {
        if (carObject.id) {
          this.updateCar(response.data);
        } else {
          this.addCar(response.data);
        }
        this.$bvModal.hide('tg-modal--add-car');
        setTimeout(() => {
          this.resetState();
        }, 200);
        this.$emit('car-added', response.data?.id || '');
        toast(this.$bvToast, this.$t('myCars.messages.success').toString());
      } else {
        console.error(`HTTP-Error: ${response.status}`);
        console.error(response.data);
        toast(this.$bvToast, this.$t('myCars.messages.error').toString(), ToastVariants.DANGER);
      }
      this.isLoading = false;
    },
    async deleteCarImage() {
      const { id } = this.car;
      if (id) {
        const { status, data } = await deleteCarImage(id);
        if (status === 200) {
          this.updateCar(data); // update car in general state
          this.loadCar(this.getCarById(id)); // update modal state
          this.setStepValid(false);
          toast(this.$bvToast, this.$t('myCars.messages.success').toString());
        }
      }
    },
  },
});
