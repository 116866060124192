
import Vue from 'vue';
import AtomSelectButton, { SelectButtonChangeEvent } from '@/components/atoms/AtomSelectButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'OrganismAddCarFuel',
  components: { AtomSelectButton },
  data() {
    return {
      selection: Array(5).fill(false),
    };
  },
  methods: {
    ...mapGetters('createCar', ['getEngineType']),
    ...mapActions('createCar', ['setEngineType']),
    processSelection(event: SelectButtonChangeEvent) {
      if (event.isSelected) {
        const selectedIndex = parseInt(event.id.replace('tg-select-add-car-fuel-', ''), 10);
        this.selection = this.selection.map((_, index) => {
          if (selectedIndex === index) return true;
          return false;
        });
        let engineType: string;
        switch (selectedIndex) {
          case 0:
            engineType = 'gasoline';
            break;
          case 1:
            engineType = 'diesel';
            break;
          case 2:
            engineType = 'electric';
            break;
          case 3:
            engineType = 'hybrid';
            break;
          default:
            engineType = '';
            break;
        }
        this.setEngineType(engineType);
        this.$emit('validate', true);
      } else {
        this.setEngineType(null);
        this.$emit('validate', false);
      }
    },
  },
  mounted() {
    if (this.getEngineType() === null) {
      this.$emit('validate', false);
    } else {
      let selectedIndex: number;
      switch (this.getEngineType()) {
        case 'gasoline':
          selectedIndex = 0;
          break;
        case 'diesel':
          selectedIndex = 1;
          break;
        case 'electric':
          selectedIndex = 2;
          break;
        case 'hybrid':
          selectedIndex = 3;
          break;
        default:
          selectedIndex = 4;
          break;
      }
      this.selection = this.selection.map((_, index) => {
        if (selectedIndex === index) return true;
        return false;
      });
      this.$emit('validate', true);
    }
  },
});
