
import AtomInputStepper, { StepperSize } from '@/components/atoms/AtomInputStepper.vue';
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'OrganismAddCarSetSeats',
  components: { AtomInputStepper },
  data() {
    return { StepperSize };
  },
  computed: {
    ...mapGetters('createCar', ['getFreeSeats']),
  },
  methods: {
    ...mapActions('createCar', ['setFreeSeats']),
  },
});
